import Impressum from "../components/Impressum";

const ImpressumScreen = () => {
  return (
    <div>
      <Impressum />
    </div>
  );
};

export default ImpressumScreen;
